import React from "react";

function InicioCentro() {
    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="max-w-2xl mx-auto mb-10 lg:mb-14">
                <h2 className="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">Presentación y explicación de programa de liquidación.</h2>
                <br />
                <p className="mt-1 text-start text-gray-500">
                    El presente es un desarrollo creado para el cálculo y presentación de la liquidación, acorde a los parámetros de sentencia en el juicio caratulado "Centro de Sub. Of. del Serv. Pen. Bonaerense C/IPS y otros s/Reconocimiento de derechos", en trámite por ante el Juzgado Contencioso Administrativo Nº4 del Dto. Judicial La Plata, Provincia de Buenos Aires.
                </p>
                <p className="mt-1 text-start text-gray-500">
                    El programa diseñado para dicho cálculo, cuenta con diferentes procesos y aplicación de diferentes lenguajes de programación a los fines de obtener los archivos descargables y el archivo denominado JSON que sirve de base para los cálculos automáticos que se efectuan en esta web.
                </p>

            </div>

            <div className="max-w-2xl mx-auto text-start divide-y divide-gray-200 dark:divide-gray-700">
                <div className="py-8 first:pt-0 last:pb-0">
                    <div className="flex gap-x-5">
                        <svg className="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                        <div>
                            <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Como se inicia el proceso?
                            </h3>
                            <p className="mt-1 text-gray-500">
                                Usando un algoritmo diseñado en lenguaje de programación "PYTHON" se toma un archivo excel en el cual figura el listado total de afiliados al CSRSPBA. El programa itera por cada afiliado, verifica su fecha de jubilación, si está dentro del período de bonificaciones no cobradas, identifica la primera bonificación debida. Para cada bonificación no abonada realiza el mismo cálculo de forma individual: calcula intereses generados en % y $, desde la fecha en que se debió abonar hasta la fecha de actualización en la que se realiza el cálculo.
                            </p>
                            <p className="mt-1 text-gray-500">
                                El programa realiza los cálculos de actualización de manera individual para cada período del socio y una vez concluido continúa con el siguiente socio.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="py-8 first:pt-0 last:pb-0">
                    <div className="flex gap-x-5">
                        <svg className="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                        <div>
                            <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Dónde es recogida esa información?
                            </h3>
                            <p className="mt-1 text-gray-500">
                                El programa genera un archivo JSON (JavaScript Object Notation) es un formato ligero de intercambio de datos que se utiliza para almacenar y transmitir información estructurada. Es necesaria la utilización de este formato puesto que el volumen de datos es inmenso y muy dificil de utilizar de otro modo (por ejemplo en un archivo de Microsoft Excel).
                            </p>
                        </div>
                    </div>
                </div>

                <div className="py-8 first:pt-0 last:pb-0">
                    <div className="flex gap-x-5">
                        <svg className="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                        <div>
                            <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Cómo ver la información obtenida?
                            </h3>
                            <p className="mt-1 text-gray-500">
                                Para tal cometido fue diseñada esta sección web utilizando lenguaje JavaScript (REACT JS), a través de la cual se dan diferentes opciones para visualizar y corroborar los resultados.
                            </p>
                            <p className="mt-1 text-gray-500">
                                En primer lugar se muestra una opción de "Liquidación Total" que muestra los resultados totales. Allí se suman todos los totales de todos los períodos de todos los socios, mostrándose los subtotales según corresponda.
                            </p>
                            <p className="mt-1 text-gray-500">
                                Se pone a disposición además una sección para descargar la liquidación en resumen, esto es por socio, mostrando el total de bonificaciones debidas, el total de intereses generados, el SAC proporcional de la acreencia y por último la suma definitiva en favor da cada socio del Centro.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="py-8 first:pt-0 last:pb-0">
                    <div className="flex gap-x-5">
                        <svg className="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                        <div>
                            <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Como verificar la corrección de los datos de manera individual?
                            </h3>
                            <p className="mt-1 text-gray-500">
                                Para ello se diseñó la sección de Búsquda por DNI para evitar errores y/o duplicaciones en el resultado de la búsqueda. Al encontrar el socio beneficiario, se muestra un resumen del socio y su información, y se ofrece la opción de generar el detalla de cada período adeudado.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="py-8 first:pt-0 last:pb-0">
                    <div className="flex gap-x-5">
                        <svg className="flex-shrink-0 mt-1 w-6 h-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><path d="M12 17h.01" /></svg>

                        <div>
                            <h3 className="md:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                Qué tecnologías fueron necesarias para la implementación?
                            </h3>
                            <p className="mt-1 text-gray-500">
                                Como fue mencionado se utilizaron los lenguajes Python, JavaScript (y su librería React Js) al mismo tiempo que la asistencia de Inteligencia Artificial, para verificar el correcto funcionamiento del código y sus resultados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InicioCentro;