import { Link } from 'react-router-dom';
import { MetaTags } from '@wellbees/meta-tag-seo';
import { useEffect } from 'react';

const whatsappIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48" fill="#ffffff" stroke="#ffffff" strokeWidth="1.584" className='mr-2'>
    <path fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" d="M24,2.5A21.52,21.52,0,0,0,5.15,34.36L2.5,45.5l11.14-2.65A21.5,21.5,0,1,0,24,2.5ZM13.25,12.27h5.86a1,1,0,0,1,1,1,10.4,10.4,0,0,0,.66,3.91,1.93,1.93,0,0,1-.66,2.44l-2.05,2a18.6,18.6,0,0,0,3.52,4.79A18.6,18.6,0,0,0,26.35,30l2-2.05c1-1,1.46-1,2.44-.66a10.4,10.4,0,0,0,3.91.66,1.05,1.05,0,0,1,1,1v5.86a1.05,1.05,0,0,1-1,1,23.68,23.68,0,0,1-15.64-6.84,23.6,23.6,0,0,1-6.84-15.64A1.07,1.07,0,0,1,13.25,12.27Z" />
  </svg>
);

const phoneIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#FFFFFF" className='mr-2'>
    <path d="m720-576-51-51 57-57H528v-72h198l-57-58 51-50 144 144-144 144Zm43 431q-121-9-229.5-59.5T339-341q-86-86-135.5-194T144-764q-2-21 12.29-36.5Q170.57-816 192-816h136q17 0 29.5 10.5T374-779l24 106q2 13-1.5 25T385-628l-97 98q20 38 46 73t57.97 65.98Q422-361 456-335.5q34 25.5 72 45.5l99-96q8-8 20-11.5t25-1.5l107 23q17 5 27 17.5t10 29.5v136q0 21.43-16 35.71Q784-143 763-145ZM255-600l70-70-17.16-74H218q5 38 14 73.5t23 70.5Zm344 344q35.1 14.24 71.55 22.62Q707-225 744-220v-90l-75-16-70 70ZM255-600Zm344 344Z" />
  </svg>
);

const emailIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#FFFFFF" className='mr-2'>
    <path d="M168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Zm312-240L168-611v347h624v-347L480-432Zm0-85 312-179H168l312 179Zm-312-94v-85 432-347Z" />
  </svg>
);

const websiteIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#FFFFFF" className='mr-2'>
    <path d="M480-96q-79 0-149-30t-122.5-82.5Q156-261 126-331T96-480q0-80 30-149.5t82.5-122Q261-804 331-834t149-30q80 0 149.5 30t122 82.5Q804-699 834-629.5T864-480q0 79-30 149t-82.5 122.5Q699-156 629.5-126T480-96Zm0-75q17-17 34-63.5T540-336H420q9 55 26 101.5t34 63.5Zm-91-10q-14-30-24.5-69T347-336H204q29 57 77 97.5T389-181Zm182 0q60-17 108-57.5t77-97.5H613q-7 47-17.5 86T571-181ZM177-408h161q-2-19-2.5-37.5T335-482q0-18 .5-35.5T338-552H177q-5 19-7 36.5t-2 35.5q0 18 2 35.5t7 36.5Zm234 0h138q2-20 2.5-37.5t.5-34.5q0-17-.5-35t-2.5-37H411q-2 19-2.5 37t-.5 35q0 17 .5 35t2.5 37Zm211 0h161q5-19 7-36.5t2-35.5q0-18-2-36t-7-36H622q2 19 2.5 37.5t.5 36.5q0 18-.5 35.5T622-408Zm-9-216h143q-29-57-77-97.5T571-779q14 30 24.5 69t17.5 86Zm-193 0h120q-9-55-26-101.5T480-789q-17 17-34 63.5T420-624Zm-216 0h143q7-47 17.5-86t24.5-69q-60 17-108 57.5T204-624Z" />
  </svg>
);

const addressIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#FFFFFF" className='mr-2'>
    <path d="m600-144-240-72-153 51q-23 8-43-6t-20-40v-498q0-16 9.5-28.5T177-755l183-61 240 72 153-51q23-10 43 5t20 41v498q0 16-9 29t-24 17l-183 61Zm-36-86v-450l-168-50v450l168 50Zm72-2 108-36v-448l-108 36v448Zm-420-12 108-36v-448l-108 36v448Zm420-436v448-448Zm-312-48v448-448Z" />
  </svg>
);

const shareIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
    <path d="M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-640q17 0 28.5-11.5T760-760q0-17-11.5-28.5T720-800q-17 0-28.5 11.5T680-760q0 17 11.5 28.5T720-720ZM240-440q17 0 28.5-11.5T280-480q0-17-11.5-28.5T240-520q-17 0-28.5 11.5T200-480q0 17 11.5 28.5T240-440Zm480 280q17 0 28.5-11.5T760-200q0-17-11.5-28.5T720-240q-17 0-28.5 11.5T680-200q0 17 11.5 28.5T720-160Zm0-600ZM240-480Zm480 280Z" />
  </svg>
);

const Card = () => {

  useEffect(() => {
    // Verificar si la Web Share API está disponible en el navegador
    if (navigator.share) {
      const shareButton = document.querySelector('#share-button');

      // Agregar evento de click para ejecutar la función de compartir
      shareButton.addEventListener('click', () => {
        navigator.share({
          title: 'Compartir Tarjeta',
          url: 'https://antoniovampa.com.ar/Card',
        })
          .then(() => console.log('Enlace compartido con éxito.'))
          .catch((error) => console.error('Error al compartir el enlace:', error));
      });
    }
  }, []);

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center p-4" style={{ height: '80vh', overflow: 'hidden' }}>
      <MetaTags
        title='Estudio Antonio-Vampa | Tarjeta'
        description='Descubre nuestros más de 40 años de experiencia legal en La Plata. Abogados Especializados en Derecho Administrativo, Derecho Laboral, Sucesiones, Reclamaciones civiles, contratos y asesoría penal. Brindamos Asistencia Jurídica Integral a Empresas y Personas.'
        keywords='Estudio Jurídico La Plata, 40 años de trayectoria legal, Experiencia Derecho Administrativo, Derecho Laboral especializado, Sucesiones legales, Reclamaciones civiles abogados, Contratos La Plata, Asesoría penal profesional, Asistencia Jurídica Integral, Consultoría legal La Plata, Servicios legales corporativos, Bufete de abogados La Plata, Despacho jurídico La Plata'
        ogTitle='Estudio Antonio-Vampa | Tarjeta de Contacto'
        ogDescription='Expertos en derecho laboral, administrativo y sucesiones.'
        ogImage='https://antoniovampa.com.ar/assets/Logo.webp'
        ogUrl='https://antoniovampa.com.ar/Card'
        ogType='website'
      />
      <nav className="mt-1 w-full bg-transparent border border-transparent py-3 px-4 flex flex-col items-center md:justify-between md:py-0 md:px-6 lg:px-8 xl:mx-auto" aria-label="Global">
        <div className="flex flex-col items-center space-y-2">
          <Link to="/" rel="noopener noreferrer">
            <img className="w-full h-full max-w-[55px] max-h-[55px]" src="../assets/Logo.webp" alt="Logo" />
          </Link>
          <Link className="text-logo font-primary text-2xl font-normal flex items-center" to="/" aria-label="EAV">
            Estudio&nbsp;<span className="font-black">Antonio | Vampa</span>
          </Link>
        </div>
      </nav>

      <div className="text-center mt-5 w-full flex-grow">
        <div className="space-y-4">
          <a href="https://wa.me/542216959586" target='_blank' className="flex items-center justify-center w-full max-w-sm px-6 py-3 text-gray-100 font-normal rounded-xl shadow-xl bg-gradient-to-t from-[#202124] to-[#2e2f32] text-sm font-primary mx-auto">
            {whatsappIcon}
            +54 221 6-9595-86
          </a>
          <a href="tel:542215650270" target='_blank' className="flex items-center justify-center w-full max-w-sm px-6 py-3 text-gray-100 font-normal rounded-xl shadow-xl bg-gradient-to-t from-[#202124] to-[#2e2f32] text-sm font-primary mx-auto">
            {phoneIcon}
            +54 2215650270
          </a>
          <a href="mailto:estudio@antoniovampa.com.ar" className="flex items-center justify-center w-full max-w-sm px-6 py-3 text-gray-100 font-normal rounded-xl shadow-xl bg-gradient-to-t from-[#202124] to-[#2e2f32] text-sm font-primary mx-auto">
            {emailIcon}
            estudio@antoniovampa.com.ar
          </a>
          <a href="https://antoniovampa.com.ar" target='_blank' className="flex items-center justify-center w-full max-w-sm px-6 py-3 text-gray-100 font-normal rounded-xl shadow-xl bg-gradient-to-t from-[#202124] to-[#2e2f32] text-sm font-primary mx-auto">
            {websiteIcon}
            antoniovampa.com.ar
          </a>
          <a href="https://maps.app.goo.gl/tgAPWRs7Q2NrRVwH7" target='_blank' className="flex items-center justify-center w-full max-w-sm px-6 py-3 text-gray-100 font-normal rounded-xl shadow-xl bg-gradient-to-t from-[#202124] to-[#2e2f32] text-sm font-primary mx-auto">
            {addressIcon}
            Calle 48 Nº845 4B - La Plata
          </a>
        </div>
        <div className="mt-6 flex items-center justify-center space-x-2">
          <span id="share-button" className="text-gray-900 font-primary font-bold text-sm cursor-pointer">Compartir Tarjeta</span>
          {shareIcon}
        </div>
      </div>
    </div>
  );
};

export default Card;

