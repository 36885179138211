import React from "react"
import "./ExportaciondeServicios.css"
import { MetaTags } from "@wellbees/meta-tag-seo"

function ExportaciondeServicios() {

    const fly = "../assets/ilustrations/fly.jpg"

    return (
        <div className="mt-4 max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-20">
            <MetaTags
                title="LegalTech y Proyectos | Desarrollo de Componentes Innovadores"
                description="Explora nuestro trabajo en el desarrollo de componentes aplicables al ejercicio legal y que mejoran la vida de las personas en el marco legal. Abogacía creativa, liquidaciones complejas, contratos simples y un Estudio Jurídico siempre dispuesto a la innovación."
            />
            <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                <div className="lg:col-span-3">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl lg:text-6xl dark:text-white">Exportación de servicios</h1>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Ofrecemos orientación estratégica para programadores, freelancers y profesionales similares, ayudándote a perfeccionar tu estrategia de percepción de salarios. Potencia tu éxito en el ámbito internacional con nuestro servicio de asesoramiento experto.</p>

                    <div className="mt-6 lg:mt-12">
                        <span className="text-xs font-medium text-gray-800 uppercase dark:text-gray-200">Contactanos: </span>
                        <p className="text-lg font-medium text-green-600">exp@antoniovampa.com.ar </p>
                    </div>
                </div>

                <div className="lg:col-span-4 mt-10 lg:mt-0">
                    <img className="w-full rounded-xl" src={fly} alt="Fly Export" />
                </div>
            </div>
        </div>
    )
}

export default ExportaciondeServicios;