import React from 'react';
import datos from "../../JSON/resultados_Minify.json";

const GeneradorDeDetalleIndividual = ({ id }) => {
  // Convertir el ID a número
  const idNumero = parseInt(id);

  // Buscar el socio por su ID
  const socio = Object.values(datos).find(socio => socio.id === idNumero);

  if (!socio) {
    return <div>No se encontraron datos para el socio con ID {id}</div>;
  }

  const calcularSACProporcional = (montoBonificacion, interesesPesos) => {
    return (montoBonificacion + interesesPesos) * 0.083;
  };

  const calcularTotal = (montoBonificacion, interesesPesos, sacProporcional) => {
    return montoBonificacion + interesesPesos + sacProporcional;
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="flex flex-col py-10">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden dark:border-gray-700">
            <p className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Detalle de socio: {socio.nombre}</p>
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Periodo</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Bonificación</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Intereses %</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Intereses $</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-gray-400">SAC Prop.</th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase dark:text-gray-400">Totales</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                {Object.keys(socio.deuda).map((periodo) => (
                  <tr key={periodo}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">{periodo}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{`$${socio.deuda[periodo].monto.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{`${socio.deuda[periodo].interes.toFixed(2)}%`}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{`$${socio.deuda[periodo].interes_pesos.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{`$${calcularSACProporcional(socio.deuda[periodo].monto, socio.deuda[periodo].interes_pesos).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">{`$${calcularTotal(socio.deuda[periodo].monto, socio.deuda[periodo].interes_pesos, calcularSACProporcional(socio.deuda[periodo].monto, socio.deuda[periodo].interes_pesos)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <button className="py-3 px-4 mt-4 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handlePrint}>
          Imprimir pantalla
        </button>
      </div>
    </div>
  );
};

export default GeneradorDeDetalleIndividual;
