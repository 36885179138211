import React from "react"
import "./Legaltech.css"
import { MetaTags } from "@wellbees/meta-tag-seo"

function Legaltech() {

    const img = "../assets/ilustrations/creative_mind1.jpg"
    const python = "../assets/ilustrations/py.png"
    const mongo = "../assets/ilustrations/mongo.png"
    const reactIcon = "../assets/ilustrations/react.png"

    return (
        <div className="mt-4 rounded-[25px] overflow-hidden max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-20">
            <MetaTags
                title="LegalTech y Proyectos | Desarrollo de Componentes Innovadores"
                description="Explora nuestro trabajo en el desarrollo de componentes aplicables al ejercicio legal y que mejoran la vida de las personas en el marco legal. Abogacía creativa, liquidaciones complejas, contratos simples y un Estudio Jurídico siempre dispuesto a la innovación."
            />
            <div id="grid_legal" className="grid md:grid-cols-2 gap-4 md:gap-4 xl:gap-15 md:items-center lg:grid-cols-8">
                <div className="lg:col-span-5">
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">LegalTech y <span className="text-blue-600">Proyectos</span></h1>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Trabajamos en el desarrollo de componenetes aplicables al ejecicio de la profesión y al mismo tiempo que faciliten la vida de las personas en el marco legal en el que todos estamos inmersos.</p>

                    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                        <div className="max-w-4xl mx-auto">
                            <div className="grid md:grid-cols-2 gap-3 lg:gap-1">
                                <div className="space-y-6 lg:space-y-10">
                                    <div className="flex">
                                        <svg className="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect width="18" height="10" x="3" y="11" rx="2" /><circle cx="12" cy="5" r="2" /><path d="M12 7v4" /><line x1="8" x2="8" y1="16" y2="16" /><line x1="16" x2="16" y1="16" y2="16" /></svg>
                                        <div className="ms-5 sm:ms-8">
                                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                                Abogacía creativa
                                            </h3>
                                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                                La rigidez normativa no excluye la creatividad. Creemos en la mejora de la aplicación del derecho a través de la tecnología, acelerando procesos judiciales y extrajudiciales para lograr mayor justicia.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <svg className="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" /></svg>
                                        <div className="ms-5 sm:ms-8">
                                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                                Liquidaciones complejas mediante componentes
                                            </h3>
                                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                                Desarrollamos un componente en Python y MongoDB, capaz de realizar liquidaciones complejas, permitiendo el control y actualización de cálculos de suma complejidad.
                                            </p>
                                            <div className="flex">
                                                <img src={python} alt="" />
                                                <img src={mongo} alt="" />
                                                <img src={reactIcon} alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="space-y-6 lg:space-y-10">
                                    <div className="flex">
                                        <svg className="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" /><circle cx="9" cy="7" r="4" /><path d="M22 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                                        <div className="ms-5 sm:ms-8">
                                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                                Contractos simples
                                            </h3>
                                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                                Estamos trabajando en el desarrollo de una aplicación capaz de facilitar la celebración de instrumentos legales de todo tipo.
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <svg className="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M7 10v12" /><path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" /></svg>
                                        <div className="ms-5 sm:ms-8">
                                            <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                                Simples pero inquietos
                                            </h3>
                                            <p className="mt-1 text-gray-600 dark:text-gray-400">
                                                Proponemos un Estudio Jurídico moderno, eficaz y siempre dispuesto a la adaptación de los contextos actuales, sociales y tecnológicos. Somos, simples, hablamos claro y siempre exploramos lo nuevo.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="lg:col-span-3 relative ms-4">
                    <img className="flex items-start w-full rounded-md" src={img} alt="LegalTech" />
                    <div className="absolute inset-0 -z-[1] bg-gradient-to-tr from-gray-200 via-white/0 to-white/0 w-full h-full rounded-md mt-4 -mb-4 me-4 -ms-4 lg:mt-6 lg:-mb-6 lg:me-6 lg:-ms-6 dark:from-slate-800 dark:via-slate-900/0 dark:to-slate-900/0"></div>

                    <div className="absolute bottom-0 start-0">
                        <svg className="w-2/3 ms-auto h-auto text-white dark:text-slate-900" width="630" height="451" viewBox="0 0 630 451" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="531" y="352" width="99" height="99" fill="currentColor" />
                            <rect x="140" y="352" width="106" height="99" fill="currentColor" />
                            <rect x="482" y="402" width="64" height="49" fill="currentColor" />
                            <rect x="433" y="402" width="63" height="49" fill="currentColor" />
                            <rect x="384" y="352" width="49" height="50" fill="currentColor" />
                            <rect x="531" y="328" width="50" height="50" fill="currentColor" />
                            <rect x="99" y="303" width="49" height="58" fill="currentColor" />
                            <rect x="99" y="352" width="49" height="50" fill="currentColor" />
                            <rect x="99" y="392" width="49" height="59" fill="currentColor" />
                            <rect x="44" y="402" width="66" height="49" fill="currentColor" />
                            <rect x="234" y="402" width="62" height="49" fill="currentColor" />
                            <rect x="334" y="303" width="50" height="49" fill="currentColor" />
                            <rect x="581" width="49" height="49" fill="currentColor" />
                            <rect x="581" width="49" height="64" fill="currentColor" />
                            <rect x="482" y="123" width="49" height="49" fill="currentColor" />
                            <rect x="507" y="124" width="49" height="24" fill="currentColor" />
                            <rect x="531" y="49" width="99" height="99" fill="currentColor" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Legaltech;