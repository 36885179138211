import React from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "@wellbees/meta-tag-seo";


function TrabajoEAV() {
    return (
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
            <MetaTags
                title='Estudio Jurídico Antonio Vampa | Trabajá con nosotros'
                description='Si consideras que cumples con los requisitos para unirte al equipo del Estudio Antonio-Vampa, no dudes en enviarnos tu currículum vitae para que podamos considerarte.'
            />
            <h1 className="block text-4xl font-bold text-gray-800 sm:text-4xl dark:text-white">Sumate al Equipo del EAV</h1>
            <p className="mt-3 text-gray-600 dark:text-gray-400">Si consideras que cumples con los requisitos para unirte al equipo del <br />Estudio Antonio-Vampa, no dudes en enviarnos tu currículum vitae para que podamos considerarte.</p>
            <p className="text-blue-600 text-2xl font-extralight dark:text-gray-400">cvs@antoniovampa.com.ar</p>
            <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
                <Link className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" to="/">
                    <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                    Volver a la página principal
                </Link>
            </div>
        </div>
    )
}

export default TrabajoEAV;