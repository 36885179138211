import React from "react";
import { Link } from "react-router-dom";
import "./Servicios.css"
import { MetaTags } from "@wellbees/meta-tag-seo";


function DAmdin() {

    const registerGAEvent = (eventName) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', eventName, {
                'event_category': 'engagement',
                'event_label': 'WhatsApp Button_Admin',
                'value': 1
            });
        }
    };

    const handleWhatsAppClick = (event) => {
        if (event.type === 'Click_wapp') {
            registerGAEvent('Click_wapp');
        }
    };


    const suc = [
        {
            title: "Derecho Administrativo",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                <path fillRule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clipRule="evenodd" />
                <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
            </svg>,
            items: [
                'Obra Pública y licitaciones',
                'Contratos Administrativos',
                'Descargos y recursos Administrativos',
                'Empleo Público y Sumarios Administrativos',
                'Expropiaciones',
                'Responsabilidad del Estado',
                'Fuero Contenicoso Administrativo',
                'Reajustes 3% antiguedad y previsionales',
                'Amparo por mora de la administración',
            ]
        }
    ]

    const svgItem = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
        <path fillRule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" /></svg>

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-7 lg:px-16 lg:py-10 mx-auto">
            <MetaTags
                title="Derecho Administrativo | Estudio Jurídico Antonio Vampa | Abogados La Plata"
                description="En EAV somos expertos en Derecho Administrativo. Obras públicas, licitaciones, contratos, expropiaciones y responsabilidad del Estado."
                keywords="Derecho Administrativo, obras públicas, licitaciones, contratos administrativos, descargos, recursos administrativos, empleo público, sumarios administrativos, expropiaciones, responsabilidad del Estado, fuero contencioso administrativo, reajustes previsionales, amparo por mora, abogados La Plata"
            />

            <div className="grid md:grid-cols-2 gap-5">
                <div className="lg:w-3/10">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        Derecho Administrativo
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-gray-400">
                        En Estudio Jurídico Antonio Vampa, ofrecemos servicios especializados en Derecho Administrativo con un enfoque preciso y profesional. Contamos con amplia experiencia en la gestión de obras públicas y licitaciones, así como en la redacción y negociación de contratos administrativos. Brindamos asistencia en la preparación y presentación de descargos y recursos administrativos, y representamos a nuestros clientes en casos de empleo público y sumarios administrativos. También gestionamos procesos de expropiaciones, asegurando la justa compensación y protección de derechos.
                        <br /><br />
                        Adicionalmente, asesoramos en cuestiones de responsabilidad del Estado y actuamos en el fuero contencioso administrativo, defendiendo los intereses de nuestros clientes en litigios contra la administración pública. Nuestro equipo maneja casos de reajustes del 3% de antigüedad y previsionales, y ofrece soluciones efectivas en amparos por mora de la administración. En Estudio Jurídico Antonio Vampa, nuestra prioridad es proporcionar un servicio confiable y eficaz, protegiendo los derechos de nuestros clientes en todas las etapas del proceso administrativo.
                    </p>

                    <Link to="/Contacto">
                        <p className="mt-5">
                            <p className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500" >
                                Contáctenos
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </p>
                        </p>
                    </Link>
                    <p className="mt-3">
                        <a href="https://wa.me/542216959586" target="_blank" rel="noopener noreferrer" 
                        className="whatsapp-button_Admin"
                        onClick={handleWhatsAppClick}>
                            <span className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                Envíenos un WhatsApp
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </span>
                        </a>
                    </p>
                </div>

                <div id="materias_box" className="space-y-6 lg:space-y-10">
                    {suc.map(points => (
                        <div id="item_box">
                            <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                                {points.iconSVG}
                            </span>
                            <div className="ms-5 sm:ms-0.01">
                                <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    {points.title}
                                </h3>
                                <ul>
                                    {points.items.map(item => (
                                        <li className="flex">
                                            {svgItem}
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>


    )
}

export default DAmdin;