import React from "react";
import { MetaTags } from "@wellbees/meta-tag-seo";

function Estudio() {
    return (
        <div className="max-w-[85rem] px-4 py-0.5 sm:px-7 lg:px-14 lg:py-0 mx-auto">
            <MetaTags
                title='Estudio Jurídico en La Plata | Quienes Somos | Más de 40 años de trayectoria legal'
                description='Descubre nuestros más de 40 años de experiencia legal en La Plata. Especializados en Derecho Administrativo, Derecho Laboral, Sucesiones, Reclamaciones civiles, contratos y asesoría penal. Brindamos Asistencia Jurídica Integral a Empresas y Personas.'
                keywords='Estudio Jurídico La Plata, 40 años de trayectoria legal, Experiencia Derecho Administrativo, Derecho Laboral especializado, Sucesiones legales, Reclamaciones civiles abogados, Contratos La Plata, Asesoría penal profesional, Asistencia Jurídica Integral, Consultoría legal La Plata, Servicios legales corporativos, Bufete de abogados La Plata, Despacho jurídico La Plata'
            />

            <div className="mt-4 lg:mt-8 lg:grid-cols-1 gap-1 lg:gap-12">
                <div className="lg:col-span-1 align-top">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        El Estudio
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-gray-400">
                        El Estudio cuenta con una extensa trayectoria de más de 40 años en toda la Provincia de Buenos Aires, Capital Federal, y corresponsalías en todo el país. Iniciado por el Dr. Marcelo Miguel Vampa, que con su excelencia y profundo conocimiento, jerarquizó la capacidad técnica, la experiencia y antecedentes del Estudio. Desde los inicios nos dedicamos al Derecho Administrativo y con especialización en contrataciones del estado, siendo la obra pública nacional, provincial y municipal los pilares del Estudio.
                        <br /><br />
                        Brindamos, además, un servicio jurídico integral, con gran trayectoria en reclamaciones civiles, laborales, previsionales y asesoría y representación en cuestiones penales.
                        <br /><br />
                        Los años, nos permitieron erigir una infraestructura física y profesional capaz de atender el gran cúmulo de reclamaciones de y hacia nuestros clientes, brindando al mismo tiempo un asesoramiento jurídico con incuestionables estándares profesionales de eficacia y calidad, que es avalada por la conservación de nuestros más antiguos clientes y que llevaran a nuestra trayectoria y reconocimiento que ostentamos (y que nos enorgullece), en todos los ámbitos en los que en el Estudio presta sus servicios profesionales.
                    </p>
                </div>

                {/* <!-- End Col --> */}
            </div>
            {/* <!-- End Grid --> */}
        </div>

    )
}

export default Estudio;