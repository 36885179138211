import React, { useState } from "react";
import LiquidacionTotal from "./LiquidacionTotal";
import DescargaDocs from "./DescargasDocs";
import BusquedaSocio from "./BusquedaSocio";
import InicioCentro from "./InicioCentro";

function Centro() {
    const [selectedOption, setSelectedOption] = useState('InicioCentro');

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    return (
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8 max-w-[85rem] mx-auto">
            <h1 className="block text-2xl font-bold text-gray-800 sm:text-2xl md:text-2xl lg:text-2xl dark:text-white">Liquidación Centro Sub.Of. c/IPS</h1>
            <br />
            <div className="inline-flex rounded-lg shadow-sm">
                <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:p-[1.3125rem]" onClick={() => handleOptionClick('InicioCentro')}>
                    Inicio Programa
                </button>
                <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:p-[1.3125rem]" onClick={() => handleOptionClick('Liquidacion')}>
                    Liquidación Total
                </button>
                <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:p-[1.3125rem]" onClick={() => handleOptionClick('Descargas')}>
                    Descargar documentos
                </button>
                <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 -ms-px first:rounded-s-lg first:ms-0 last:rounded-e-lg text-sm font-medium focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 sm:p-[1.3125rem]" onClick={() => handleOptionClick('Busqueda')}>
                    Buscar Afiliado
                </button>
            </div>
            {selectedOption === 'InicioCentro' && <InicioCentro />}
            {selectedOption === 'Liquidacion' && <LiquidacionTotal />}
            {selectedOption === 'Descargas' && <DescargaDocs />}
            {selectedOption === 'Busqueda' && <BusquedaSocio />}
        </div>
    )
}

export default Centro;
