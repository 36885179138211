import React from "react";
import { Link } from "react-router-dom";
import "./Servicios.css"
import { MetaTags } from "@wellbees/meta-tag-seo";


function Servicios() {

    const registerGAEvent = (eventName) => {
        window.gtag('event', eventName, {
            'event_category': 'engagement',
            'event_label': 'WhatsApp Button',
            'value': 1
        });
    }

    const materias = [
        {
            title: "Derecho Administrativo",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M11.584 2.376a.75.75 0 0 1 .832 0l9 6a.75.75 0 1 1-.832 1.248L12 3.901 3.416 9.624a.75.75 0 0 1-.832-1.248l9-6Z" />
                <path fillRule="evenodd" d="M20.25 10.332v9.918H21a.75.75 0 0 1 0 1.5H3a.75.75 0 0 1 0-1.5h.75v-9.918a.75.75 0 0 1 .634-.74A49.109 49.109 0 0 1 12 9c2.59 0 5.134.202 7.616.592a.75.75 0 0 1 .634.74Zm-7.5 2.418a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Zm3-.75a.75.75 0 0 1 .75.75v6.75a.75.75 0 0 1-1.5 0v-6.75a.75.75 0 0 1 .75-.75ZM9 12.75a.75.75 0 0 0-1.5 0v6.75a.75.75 0 0 0 1.5 0v-6.75Z" clipRule="evenodd" />
                <path d="M12 7.875a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z" />
            </svg>,
            items: [
                'Obra Pública y licitaciones',
                'Contratos Administrativos',
                'Descargos y recursos Administrativos',
                'Empleo Público y Sumarios Administrativos',
                'Expropiaciones',
                'Responsabilidad del Estado',
                'Fuero Contenicoso Administrativo',
                'Reajustes 3% antiguedad y previsionales',
                'Amparo por mora de la administración',
            ]
        },
        {
            title: "Derecho Civil y Comercial",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
            </svg>,
            items: [
                'Daños y perjuicios (accidentes de tránsito, obra, etc.)',
                'Contratos, análisis de riesgos e incumplimientos',
                'Sucesiones y Planificación Hereditaria',
                'Cobros ejecutivos',
                'Usucapiones',
                'Desalojos',
            ]
        },
        {
            title: "Derecho Laboral",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fill-rule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clip-rule="evenodd" />
                <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
            </svg>
            ,
            items: [
                'Despidos',
                'Accidentes Laborales y Enfermedades (ART)',
                'Trabajo no registrado (en negro)',
                'Reajustes',
            ]
        },
        {
            title: "Derecho Penal",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fill-rule="evenodd" d="M12 2.25a.75.75 0 0 1 .75.75v.756a49.106 49.106 0 0 1 9.152 1 .75.75 0 0 1-.152 1.485h-1.918l2.474 10.124a.75.75 0 0 1-.375.84A6.723 6.723 0 0 1 18.75 18a6.723 6.723 0 0 1-3.181-.795.75.75 0 0 1-.375-.84l2.474-10.124H12.75v13.28c1.293.076 2.534.343 3.697.776a.75.75 0 0 1-.262 1.453h-8.37a.75.75 0 0 1-.262-1.453c1.162-.433 2.404-.7 3.697-.775V6.24H6.332l2.474 10.124a.75.75 0 0 1-.375.84A6.723 6.723 0 0 1 5.25 18a6.723 6.723 0 0 1-3.181-.795.75.75 0 0 1-.375-.84L4.168 6.241H2.25a.75.75 0 0 1-.152-1.485 49.105 49.105 0 0 1 9.152-1V3a.75.75 0 0 1 .75-.75Zm4.878 13.543 1.872-7.662 1.872 7.662h-3.744Zm-9.756 0L5.25 8.131l-1.872 7.662h3.744Z" clip-rule="evenodd" />
            </svg>,
            items: [
                'Denuncia',
                'Querellas',
                'Defensas',
                'Recursos Extraordinarios',
            ]
        },
        {
            title: "Especiales",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
                <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
                <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
            </svg>,
            items: [
                'Amparos de Salud',
                'Defensa del consumidor',
                'Reclamos aereos',
                'Cumplimiento regulatorio',
                'Seguros Colectivos o por incapacidad',
            ]
        },
    ]

    const svgItem = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
        <path fillRule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" /></svg>

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-7 lg:px-16 lg:py-10 mx-auto">
            <MetaTags
                title="Estudio Antonio Vampa | Servicios y Materias | Asesoramiento Legal Integral | EAV"
                description="Descubre el asesoramiento legal integral del Estudio Antonio Vampa. Ofrecemos soluciones efectivas en Derecho Administrativo, Civil y Comercial, Derecho Laboral, Penal y más. Contáctanos para recibir un servicio jurídico de calidad en La Plata y Buenos Aires."
                keywords="Estudio Antonio Vampa, asesoramiento legal integral, Derecho Administrativo, Derecho Civil, Derecho Comercial, Derecho Laboral, Derecho Penal, La Plata, Buenos Aires, servicios legales, abogados expertos, consultoría jurídica, bufete de abogados"
            />

            <div className="grid md:grid-cols-2 gap-5">
                <div className="lg:w-3/10">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        Servicios y materias del Estudio
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-gray-400">
                        En EAV, nos enorgullece ofrecer un asesoramiento legal integral que abarca una amplia gama de materias. Nuestra misión es brindar soluciones efectivas tanto a empresas como a personas, proporcionando un servicio jurídico de calidad que se distingue por su excelencia y compromiso.
                    </p>
                    <Link to="/Contacto">
                        <p className="mt-5">
                            <p className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500" >
                                Contáctenos
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </p>
                        </p>
                    </Link>
                </div>

                <div id="materias_box" className="space-y-6 lg:space-y-10">
                    {materias.map(points => (
                        <div id="item_box">
                            <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                                {points.iconSVG}
                            </span>
                            <div className="ms-5 sm:ms-0.01">
                                <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    {points.title}
                                </h3>
                                <ul>
                                    {points.items.map(item => (
                                        <li className="flex">
                                            {svgItem}
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                                {points.title === "Derecho Laboral" && (
                                    <Link to="/DerechoLaboral" className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                        Ver más
                                        <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                                    </Link>
                                )}
                                {points.title === "Derecho Administrativo" && (
                                    <Link to="/Administrativo" className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                        Ver más
                                        <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                                    </Link>
                                )}
                                {points.title === "Derecho Civil y Comercial" && (
                                    <ul>
                                        {points.items.map(item => (
                                            <li className="flex">
                                                {svgItem}
                                                {item === 'Sucesiones y planificación hereditaria' ? (
                                                    <Link to="/Sucesiones" className="text-blue-600 dark:text-blue-500">
                                                        <p>{item}</p>
                                                    </Link>
                                                ) : (
                                                    <p>{item}</p>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}

                            </div>
                        </div>
                    ))}
                </div>
                <div className="lg:w-3/10">
                    <Link to="/Contacto">
                        <p className="mt-5">
                            <p className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500" >
                                Contáctenos
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </p>
                        </p>
                    </Link>
                    <p className="mt-3">
                        <a href="https://wa.me/542216959586" target="_blank" rel="noopener noreferrer" onClick={() => registerGAEvent('Click_wapp')}>
                            <p className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                Envíenos un WhatsApp
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </p>
                        </a>
                    </p>
                </div>
            </div>
        </div>


    )
}

export default Servicios;