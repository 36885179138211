import React from "react";
import InicioHero from "../InicioHero/InicioHero";
import OurVision from "../OurVision/OurVision";
import { MetaTags } from "@wellbees/meta-tag-seo";
import Anuncio from "../Anuncio/Anuncio";

function Home() {
    return (
        <div>
            <MetaTags
                title='Estudio Jurídico Antonio Vampa | Asesoría Legal en La Plata'
                description='Descubre nuestra asesoría legal y jurídica en La Plata con más de 40 años de experiencia. Especialistas en Derecho Administrativo, Laboral, Sucesiones Civil y Comercial y Penal. Contáctanos para obtener ayuda legal.'
                keywords='Derecho laboral, Derecho administrativo, Sucesiones'
                ogTitle='Estudio Antonio-Vampa | Abogados'
                ogDescription='Expertos en derecho laboral, administrativo y sucesiones.'
                ogImage='https://antoniovampa.com.ar/assets/Logo.webp'
                ogUrl='https://antoniovampa.com.ar/'
                ogType='website'
            />

            <InicioHero />
            <Anuncio />
            <OurVision />
        </div>
    )
}

export default Home;