import React from "react";
import { Link } from "react-router-dom";
import "./Servicios.css"
import { MetaTags } from "@wellbees/meta-tag-seo";


function EmpresasyPersonas() {

    const empresas = [
        {
            title: "Derecho Empresarial",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M5.223 2.25c-.497 0-.974.198-1.325.55l-1.3 1.298A3.75 3.75 0 0 0 7.5 9.75c.627.47 1.406.75 2.25.75.844 0 1.624-.28 2.25-.75.626.47 1.406.75 2.25.75.844 0 1.623-.28 2.25-.75a3.75 3.75 0 0 0 4.902-5.652l-1.3-1.299a1.875 1.875 0 0 0-1.325-.549H5.223Z" />
                <path fill-rule="evenodd" d="M3 20.25v-8.755c1.42.674 3.08.673 4.5 0A5.234 5.234 0 0 0 9.75 12c.804 0 1.568-.182 2.25-.506a5.234 5.234 0 0 0 2.25.506c.804 0 1.567-.182 2.25-.506 1.42.674 3.08.675 4.5.001v8.755h.75a.75.75 0 0 1 0 1.5H2.25a.75.75 0 0 1 0-1.5H3Zm3-6a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm8.25-.75a.75.75 0 0 0-.75.75v5.25c0 .414.336.75.75.75h3a.75.75 0 0 0 .75-.75v-5.25a.75.75 0 0 0-.75-.75h-3Z" clip-rule="evenodd" />
            </svg>,
            items: [
                'Asesoramiento externo permanente',
                'Representación o asistencia en reuniones o asambleas',
                'Dictámenes Jurídicos',
                'Representación Jurídica Integral por materia o asignación',
                'Due Diligence',
                'Compliance',
                'Elaboración reglas y políticas internas',
                'Negociación de contratos civiles y laborales',
            ]
        },
    ]

    const svgItem = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
        <path fillRule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" /></svg>

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-7 lg:px-16 lg:py-10 mx-auto">
            <MetaTags
                title="Asesoramiento Legal para Empresas y Personas | Servicio Completo | EAV"
                description="Brindamos asesoramiento legal completo a empresas y personas. Análisis de decisiones ejecutivas, identificación de riesgos, representación jurídica y soporte integral. Contáctenos para obtener respaldo legal dinámico y comprometido."
                keywords="Asesoramiento legal, Derecho Empresarial, Representación Jurídica, Due Diligence, Compliance, Contratos Civiles, Contratos Laborales, Dictámenes Jurídicos, EAV Estudio Jurídico"
            />
            <div className="grid md:grid-cols-2 gap-5">
                <div className="lg:w-3/10">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        Asesoramiento legal a Empresas y Personas
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-gray-400">
                        Ofrecemos un respaldo legal complejo y dinámico, atendiendo a las demandas globales de la empresa, brindando soporte antes y después de la toma de decisiones ejecutivas, analizando los efectos y brindando opiniones jurídicas. Nos concentramos en identificar riesgos y conflictos que demandan soluciones específicas, ya sea a corto, mediano o largo plazo. Nos involucramos plenamente y asumimos el compromiso, siendo capaces de dar un asesoramiento jurídico todo en uno.
                        <br /><br />
                        Al mismo tiempo, proporcionamos un soporte legal completo para individuos. Estamos presentes, asesoramos y defendemos tus intereses y derechos en todas las instancias y reclamos, cubriendo de manera integral todas las necesidades de nuestros clientes.
                    </p>
                    <Link to="/Contacto">
                        <p className="mt-5">
                            <p className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500" >
                                Contáctenos
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </p>
                        </p>
                    </Link>
                </div>

                <div id="materias_box" className="space-y-6 lg:space-y-10">
                    {empresas.map(points => (
                        <div id="item_box">
                            <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                                {points.iconSVG}
                            </span>
                            <div className="ms-5 sm:ms-0.01">
                                <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    {points.title}
                                </h3>
                                <ul>
                                    {points.items.map(item => (
                                        <li className="flex">
                                            {svgItem}
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>


    )
}

export default EmpresasyPersonas;