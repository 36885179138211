import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './index.css';
import './App.css';
import Preline from 'preline';
import Home from './components/Home/Home';
import NavBar from './components/NavBar/NavBar';
import CardServices from './components/CardServices/CardServices';
import Footer from './components/Footer/Footer';
import Error from './components/Error/Error';
import Estudio from './components/Pages/Estudio/Estudio';
import Servicios from './components/Pages/Servicios/Servicios';
import EmpresasyPersonas from './components/Pages/Servicios/EmpresasyPersonas';
import Legaltech from './components/Pages/LegalTech/Legaltech';
import ExportaciondeServicios from './components/Pages/ExportaciondeServicios/ExportaciondeServicios';
import TrabajoEAV from './components/Pages/TrabajoEAV/TrabajoEAV';
import Contacto from './components/Pages/Contacto/Contacto';
import Centro from './components/Centro/Centro';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Antiguedad from './components/Antiguedad/Antiguedad';
import CalculoRetroactivo from './components/Antiguedad/CalculoRetroactivo';
import WhatsAppButton from './components/WhatsAppButton/WhatsAppButton';
import DLaboral from './components/Pages/Servicios/Dlaboral';
import Sucesiones from './components/Pages/Servicios/Sucesiones';
import DAmdin from './components/Pages/Servicios/Administrativo';
import Card from './components/Card/Card';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<><NavBar /><Home /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Estudio' element={<><NavBar /><Estudio /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Servicios' element={<><NavBar /><Servicios /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/EmpresasyPersonas' element={<><NavBar /><EmpresasyPersonas /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Legaltech' element={<><NavBar /><Legaltech /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/ExportaciondeServicios' element={<><NavBar /><CardServices /><ExportaciondeServicios /><Footer /><WhatsAppButton /></>} />
          <Route path='/TrabajaconNosotros' element={<><NavBar /><TrabajoEAV /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Contacto' element={<><NavBar /><Contacto /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Centro' element={<><NavBar /><Centro /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Antiguedad' element={<><NavBar /><Antiguedad /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/retro' element={<><NavBar /><CalculoRetroactivo /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/DerechoLaboral' element={<><NavBar /><DLaboral /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Sucesiones' element={<><NavBar /><Sucesiones /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Administrativo' element={<><NavBar /><DAmdin /><CardServices /><Footer /><WhatsAppButton /></>} />
          <Route path='/Card' element={<Card />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
