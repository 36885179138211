import React, { useState } from "react";
import jsonData from "../../JSON/resultados_Minify.json";
import GeneradorDeDetalleIndividual from "./GeneradorDeDetalleIndividual";
import { MetaTags } from "@wellbees/meta-tag-seo";

function BusquedaSocio() {
  const [dni, setDni] = useState("");
  const [socioEncontrado, setSocioEncontrado] = useState(null);
  const [fechaActualizacion, setFechaActualizacion] = useState("");
  const [socioNoEncontrado, setSocioNoEncontrado] = useState(false);
  const [mostrarDetalle, setMostrarDetalle] = useState(false); // Estado para controlar la visualización del detalle

  const buscarSocio = (event) => {
    event.preventDefault();

    const dniNumero = parseFloat(dni);

    const socio = Object.values(jsonData).find(
      (socio) => socio.DNI === dniNumero
    );

    if (socio) {
      setSocioEncontrado(socio);
      setSocioNoEncontrado(false);
      setFechaActualizacion("08/02/2024");
    } else {
      setSocioEncontrado(null);
      setSocioNoEncontrado(true);
    }
  };

  const handleInputChange = (event) => {
    setDni(event.target.value);
  };

  const toggleMostrarDetalle = () => {
    setMostrarDetalle(!mostrarDetalle);
  };

  const calcularTotalBonificaciones = () => {
    if (!socioEncontrado || !socioEncontrado.deuda) return 0;
    let totalBonificaciones = 0;
    for (const periodo in socioEncontrado.deuda) {
      totalBonificaciones += socioEncontrado.deuda[periodo].monto;
    }
    return totalBonificaciones;
  };

  const calcularTotalIntereses = () => {
    if (!socioEncontrado || !socioEncontrado.deuda) return 0;
    let totalIntereses = 0;
    for (const periodo in socioEncontrado.deuda) {
      totalIntereses += socioEncontrado.deuda[periodo].interes_pesos;
    }
    return totalIntereses;
  };

  const calcularSACProporcional = () => {
    const totalBonificaciones = calcularTotalBonificaciones();
    const totalIntereses = calcularTotalIntereses();
    return (totalBonificaciones + totalIntereses) / 12;
  };

  const calcularTotal = () => {
    const totalBonificaciones = calcularTotalBonificaciones();
    const totalIntereses = calcularTotalIntereses();
    const sacProporcional = calcularSACProporcional();
    return totalBonificaciones + totalIntereses + sacProporcional;
  };

  const calcularDescuentoCSRSPBA = () => {
    const total = calcularTotal();
    return total * 0.0075;
  };

  return (
    <div>
       <MetaTags 
            title='Antonio-Vampa | CSRSPBA Busqueda de socio'
            description='Liquidación judicial Centro de Suboficiales Servicio Penitenciario Bonaerense.'
            />
      <form onSubmit={buscarSocio}>
        <div className="py-10 px-4 sm:px-6 lg:px-8 max-w-[20rem] mx-auto">
          <label htmlFor="dni" className="block text-sm font-medium mb-2 dark:text-white">Buscar DNI</label>
          <p className="text-sm">*sin puntos ni separadores(ej. 11556222)</p>
          <div className="relative flex rounded-lg shadow-sm">
            <input
              type="text"
              id="dni"
              name="dni"
              className="py-3 px-4 ps-11 block w-full border-gray-200 shadow-sm rounded-s-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
              placeholder="DNI"
              value={dni}
              onChange={handleInputChange}
            />
            <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-4">
              <svg className="flex-shrink-0 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="11" cy="11" r="8" /><path d="m21 21-4.3-4.3" /></svg>
            </div>
            <button
              type="submit"
              className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-e-md border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
            >
              Buscar
            </button>
          </div>
        </div>
      </form>
      
      {socioNoEncontrado && (
        <div className="py-2 px-4 sm:px-6 lg:px-8 max-w-[20rem] mx-auto text-red-600">
          Socio no encontrado. Por favor, ingrese un DNI válido.
        </div>
      )}

      {socioEncontrado && (
        <div className="flex flex-col py-10 px-4 sm:px-6 lg:px-8 max-w-[50rem] mx-auto">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg overflow-hidden dark:border-gray-700">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead>
                    <p className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">Resumen</p>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Fecha de actualización:</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{fechaActualizacion}</td>
                    </tr>
                    {Object.entries(socioEncontrado).map(([key, value]) => (
                      key !== "deuda" && (
                        <tr key={key} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">{key}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{typeof value === 'object' ? value.toLocaleString("es-AR", { style: "currency", currency: "ARS" }) : value}</td>
                        </tr>
                      )
                    ))}
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>Total bonificaciones debidas:</strong></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                        {calcularTotalBonificaciones().toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>Total intereses devengados:</strong></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                        {calcularTotalIntereses().toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>SAC Proporcional:</strong></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                        {calcularSACProporcional().toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>TOTAL:</strong></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                        <strong>
                          {calcularTotal().toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                        </strong>
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>Descuento CSRSPBA:</strong></td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">
                        {calcularDescuentoCSRSPBA().toLocaleString("es-AR", { style: "currency", currency: "ARS" })}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Botón para mostrar o esconder el detalle */}
          <button
            className="py-3 px-4 mt-4 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            onClick={toggleMostrarDetalle}
          >
            {mostrarDetalle ? "Ocultar Detalle" : "Generar Detalle de Períodos"}
          </button>
          {/* Componente GeneradorDeDetalleIndividual mostrado condicionalmente */}
          {mostrarDetalle && <GeneradorDeDetalleIndividual id={socioEncontrado.id} />}
        </div>
      )}
    </div>
  );
}

export default BusquedaSocio;
