import React, { useState, useEffect } from 'react';
import './InicioHero.css';
import { Link } from 'react-router-dom';

function InicioHero() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [fade, setFade] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const slides = [
    {
      objet: 'Servicios del Estudio',
      title: 'Derecho Administrativo',
      description: 'Contratos Administrativos | Expropiaciones | Fuero Contencioso | Empleo Público | Sumarios',
      classSlide: 'from-violet-600/[.15]',
      background: 'bg-slate-900',
      titleColor: 'text-white',
      textColor: 'text-white',
      to: '/Administrativo',
    },
    {
      objet: 'Servicios del Estudio',
      title: 'Derecho Laboral',
      description: 'Despidos | Accidentes y Enfermedades (ART) | Trabajo no Registrado (en negro) | Reajustes',
      classSlide: 'from-indigo-500/[.15]',
      background: 'bg-gray-800',
      titleColor: 'text-yellow-300',
      textColor: 'text-yellow-100',
      to: '/DerechoLaboral',
    },
    {
      objet: 'Servicios del Estudio',
      title: 'Asesoramiento Integral',
      description: 'Empresas y Personas | Laboral, Civil, Comercial y Penal',
      classSlide: 'from-green-700/[.15]',
      background: 'bg-slate-100',
      titleColor: 'text-slate-900',
      textColor: 'text-gray-500',
      to: '/Servicios',
    },
    {
      objet: 'El Estudio',
      title: '+40 AÑOS',
      description: 'Somos un Estudio Jurídico fundado hace más de cuatro décadas!',
      classSlide: 'from-gray-800/[.15]',
      background: 'bg-blue-100',
      titleColor: 'text-red-700',
      textColor: 'text-gray-500',
      to: '/Estudio',
    },
  ];

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        setFade(true);
        setTimeout(() => {
          setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
          setFade(false);
        }, 500); // Tiempo de la transición en milisegundos (0.5 segundos)
      }, 4000); // Intervalo de tiempo entre slides (4 segundos)

      return () => clearInterval(interval);
    }
  }, [isPaused]);

  const handleNextSlide = () => {
    setFade(true);
    setTimeout(() => {
      setActiveSlide((activeSlide + 1) % slides.length);
      setFade(false);
    }, 500); // Tiempo de la transición en milisegundos (0.5 segundos)
  };

  const handlePreviousSlide = () => {
    setFade(true);
    setTimeout(() => {
      setActiveSlide((activeSlide - 1 + slides.length) % slides.length);
      setFade(false);
    }, 500); // Tiempo de la transición en milisegundos (0.5 segundos)
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const currentSlide = slides[activeSlide];

  return (
    <div className={`${currentSlide.background} max-w-7xl mt-4 relative rounded-[36px] mx-2 py-1 px-1 xl:mx-auto h-[30rem] overflow-hidden`}>
      <div className={`carousel bg-gradient-to-b via-transparent ${currentSlide.classSlide} ${fade ? 'fade-out' : 'fade-in'}`}>
        <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10 space-y-8">

          {/* Navegador Sliders */}
          <div className="flex justify-center mt-1">
            <span
              className={`hover:bg-white/[.1] py-1 px-2 inline-flex justify-center items-center gap-x-2 rounded-full bg-white/[.075] font-semibold text-sm cursor-pointer shadow-md mx-3 carousel-control-prev ${currentSlide.textColor}`}
              onClick={handlePreviousSlide}
            >
              <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M10.9257 2L5.27921 7.64645C5.07405 7.84171 5.07405 8.15829 5.27921 8.35355L10.9257 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </span>
            <span
              className={`hover:bg-white/[.1] py-1 px-2 inline-flex justify-center items-center gap-x-2 rounded-full bg-white/[.075] font-semibold text-sm cursor-pointer shadow-md mx-3 carousel-control-toggle ${currentSlide.textColor}`}
              onClick={togglePause}
            >
              {isPaused ? (
                <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M4 2 L12 8 L4 14 Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              ) : (
                <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5 2 L5 14 M11 2 L11 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              )}
            </span>
            <span
              className={`hover:bg-white/[.1] py-1 px-2 inline-flex justify-center items-center gap-x-2 rounded-full bg-white/[.075] font-semibold text-sm cursor-pointer shadow-md mx-3 carousel-control-next ${currentSlide.textColor}`}
              onClick={handleNextSlide}
            >
              <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </span>
          </div>

          {/*<!-- Announcement Banner -->*/}
          <div className="flex justify-center">
            <Link
              className={`group inline-block bg-white/[.05] hover:bg-white/[.1] border border-white/[.05] p-1 pl-4 rounded-full shadow-md carousel-content ${fade ? 'fade-out' : 'fade-in'}`}
              to={currentSlide.to}
            >
              <p className={`mr-2 inline-block text-sm ${currentSlide.textColor}`}>
                {currentSlide.objet}
              </p>
              <span
                className={`group-hover:bg-white/[.1] py-2 px-3 inline-flex justify-center items-center gap-x-2 rounded-full bg-white/[.5] font-semibold text-white text-sm ${currentSlide.textColor}`}
              >
                <svg className={`w-2.5 h-2.5 ${currentSlide.textColor}`} width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </span>
            </Link>
          </div>
          {/*<!-- End Announcement Banner -->*/}

          {/*!-- Title -->*/}
          <div className="max-w-3xl text-center mx-auto">
            <h1
              className={`block font-medium text-grey-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl carousel-content ${fade ? 'fade-out' : 'fade-in'} ${currentSlide.titleColor}`}
            >
              {currentSlide.title}
            </h1>
          </div>

          {/*<!-- End Title -->*/}
          <div className="max-w-3xl text-center mx-auto">
            <p className={`text-lg text-gray-400 carousel-content ${fade ? 'fade-out' : 'fade-in'}`}>
              {currentSlide.description}
            </p>
          </div>

          {/*<!-- Buttons -->*/}
          <div className="text-center">
            <Link
              className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6 dark:focus:ring-offset-gray-800"
              to="/Contacto"
            >
              Contáctenos
              <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </Link>
          </div>

          {/*<!-- End Buttons -->*/}
        </div>
      </div>
    </div>
  );
}

export default InicioHero;
