import React from "react";
import { Link } from "react-router-dom";
import "./Servicios.css";
import { MetaTags } from "@wellbees/meta-tag-seo";

function DLaboral() {
    const registerGAEvent = (eventName) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', eventName, {
                'event_category': 'engagement',
                'event_label': 'WhatsApp Button_DLab',
                'value': 1
            });
        }
    };

    const handleWhatsAppClick = (event) => {
        if (event.type === 'Click_wapp') {
            registerGAEvent('Click_wapp');
        }
    };

    const empresas = [
        {
            title: "Derecho Laboral",
            iconSVG: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                    <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
                </svg>
            ),
            items: [
                'Reclamos judiciales y extrajudiciales laborales.',
                'Indemnizaciones por despido (con y sin causa).',
                'Reclamos por reajustes y diferencias salariales.',
                'Acoso laboral y discriminación.',
                'Reclamos por enfermedades laborales y accidentes laborales contra las ART.',
                'Representación ante comisiones médicas.',
                'Asesoramiento y defensa en casos de trabajo no registrado (en negro).',
            ],
        },
    ];

    const svgItem = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
        </svg>
    );

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-7 lg:px-16 lg:py-10 mx-auto">
            <MetaTags
                title="Derecho Laboral | Estudio Jurídico Antonio Vampa"
                description="Especialistas en reclamos Laborales La Plata, Indemnizaciones por Despido, Trabajo no registrado, accidentes y enfermedades laborales"
                keywords="Estudio Jurídico Labora, indemnización por despido, liquidacion final, trabajo en negro, accidente laboral, enfermedad laboral, art, comisiones médicas"
            />
            <div className="grid md:grid-cols-2 gap-5">
                <div className="lg:w-3/10">
                    <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                        Derecho Laboral
                    </h2>
                    <p className="mt-3 text-gray-800 dark:text-gray-400">
                        Nos especializamos en Derecho Laboral para brindar un respaldo legal sólido y personalizado a trabajadores individuales. Desde la defensa en reclamos por indemnizaciones por despido hasta la asistencia en casos de trabajo no registrado, accidentes laborales y enfermedades ocupacionales, estamos comprometidos con proteger tus derechos en todas las instancias legales.
                        <br /><br />
                        Ofrecemos asesoramiento integral en materia de reclamos laborales, asegurando una representación efectiva en negociaciones y procedimientos judiciales. Nuestra asistencia incluye la representación y seguimiento de reclamos ante las comisiones médicas competentes. Nos dedicamos a garantizar que recibas la compensación justa y el reconocimiento de tus derechos laborales.
                    </p>

                    <Link to="/Contacto">
                        <p className="mt-5">
                            <span className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                Contáctenos
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </span>
                        </p>
                    </Link>
                    <p className="mt-3">
                        <a href="https://wa.me/542216959586" target="_blank" rel="noopener noreferrer"
                        className="whatsapp-button_DLab"
                        onClick={handleWhatsAppClick}>
                            <span className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                Envíenos un WhatsApp
                                <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </span>
                        </a>
                    </p>
                </div>

                <div id="materias_box" className="space-y-6 lg:space-y-10">
                    {empresas.map((points) => (
                        <div id="item_box" key={points.title}>
                            <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                                {points.iconSVG}
                            </span>
                            <div className="ms-5 sm:ms-0.01">
                                <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    {points.title}
                                </h3>
                                <ul>
                                    {points.items.map((item, index) => (
                                        <li className="flex" key={index}>
                                            {svgItem}
                                            <p>{item}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default DLaboral;
