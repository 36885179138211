import React from "react";
import { Link } from "react-router-dom";

function ItemsFooter() {
    return (
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 mb-10">
            <div className="col-span-full hidden lg:col-span-1 lg:block">
                <Link to="/" >
                <img className="w-full h-full max-w-[30px] max-h-[30px]" src="../assets/Logo.webp" alt="Logo" />
                <p className="mt-3 text-xs sm:text-sm text-gray-600 dark:text-gray-400" style={{ textDecoration: 'overline'}}>© 2024 Estudio Antonio Vampa.</p>
                </Link>
            </div>

            <div>
                <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Servicios</h4>

                <div className="mt-3 grid space-y-3 text-sm">
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Administrativo">Derecho Administrativo</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Administrativo">Empleo Público / Sumarios</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Administrativo">Antigüedad y Reajustes</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Servicios">Civil y Comercial</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/DerechoLaboral">Derecho Laboral</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Servicios">Derecho Penal</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Sucesiones">Sucesiones y Planificación Hereditaria</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/EmpresasyPersonas">Empresas y Personas</Link></p>
                </div>
            </div>


            <div>
                <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">EAV</h4>

                <div className="mt-3 grid space-y-3 text-sm">
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Estudio">El Estudio</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/TrabajaconNosotros">Trabajá en EAV</Link></p>
                    <p><a className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" href="https://maps.app.goo.gl/6WdR4WWMtrPnyDVt6" target="_blank">Dónde estamos</a> </p>
                    <p><Link className="inline-flex gap-x-3 text-red-750 hover:text-gray-900 dark:text-gray-900 dark:hover:text-gray-500" to="/Contacto">Contacto</Link></p>
                    <p><a className="inline-flex gap-x-3 text-gray-700 hover:text-gray-900 dark:text-gray-100 dark:hover:text-gray-500" href="http://wa.me/542216959586" target="_blank" style={{ textDecoration: 'underline' }}>Whatsapp +542216959586</a></p>
                </div>
            </div>


            <div>
                <h4 className="text-xs font-semibold text-gray-900 uppercase dark:text-gray-100">Recursos y Proyectos</h4>

                <div className="mt-3 grid space-y-3 text-sm">
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/Antiguedad">Cálculo Antigüedad 3%</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/LegalTech">LegalTech</Link></p>
                    <p><Link className="inline-flex gap-x-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200" to="/ExportaciondeServicios">Exportación de servicios</Link></p>
                </div>
            </div>

            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.532495041585!2d-57.9589884250323!3d-34.91817997417226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e632617b5fdd%3A0xc91f2b2cc4e5c3b3!2sEstudio%20Jur%C3%ADdico%20Antonio%20-%20Vampa%20%7C%20abogados!5e0!3m2!1ses-419!2sar!4v1701882426177!5m2!1ses-419!2sar"
                width="220"
                height="220"
                style={{ border: '0' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Estudio Antonio Vampa - Ubicación"
            ></iframe>


        </div>
    )
}

export default ItemsFooter;