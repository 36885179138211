import React from "react";
import { Link } from "react-router-dom";
import { useForm, ValidationError } from '@formspree/react';
import { MetaTags } from "@wellbees/meta-tag-seo";

function Contacto() {
    const [state, handleSubmit] = useForm("xdoqoyze");
    if (state.succeeded) {
        return (
            <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
                <p className="mt-3 text-gray-600 dark:text-gray-400">GRACIAS POR SU MENSAJE</p>
                <p className="text-gray-600 dark:text-gray-400">Le contestaremos tan pronto como podamos</p>
                <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
                    <Link className="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-blue-600 hover:text-blue-800 disabled:opacity-50 disabled:pointer-events-none dark:text-blue-500 dark:hover:text-blue-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" to="/">
                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m15 18-6-6 6-6" /></svg>
                        Volver a la página principal
                    </Link>
                </div>
            </div>
        );
    }

    return (
        <div className="mt-4 max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-20">
            <MetaTags
                title='Estudio Jurídico en La Plata | Contacto | Más de 40 años de trayectoria legal'
                description='Descubre nuestros más de 40 años de experiencia legal en La Plata. Abogados Especializados en Derecho Administrativo, Derecho Laboral, Sucesiones, Reclamaciones civiles, contratos y asesoría penal. Brindamos Asistencia Jurídica Integral a Empresas y Personas.'
                keywords='Estudio Jurídico La Plata, 40 años de trayectoria legal, Experiencia Derecho Administrativo, Derecho Laboral especializado, Sucesiones legales, Reclamaciones civiles abogados, Contratos La Plata, Asesoría penal profesional, Asistencia Jurídica Integral, Consultoría legal La Plata, Servicios legales corporativos, Bufete de abogados La Plata, Despacho jurídico La Plata'
            />

            <div className="grid lg:grid-cols-7 lg:gap-x-8 xl:gap-x-12 lg:items-center">
                <div className="lg:col-span-4">
                    <h1 className="text-3xl text-gray-800 font-bold md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight dark:text-gray-200">
                        Contáctenos
                    </h1>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Estudio Jurídico Antonio-Vampa | Abogados <br />
                        <span className="text-blue-600 dark:text-blue-500">Calle 48 Nº845 Piso 4ºB La Plata, Pcia. de Buenos Aires.</span></p>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Contanto: <span className="text-blue-600 dark:text-blue-500">estudio@antoniovampa.com.ar</span></p>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Exportación: <span className="text-blue-600 dark:text-blue-500">exp@antoniovampa.com.ar</span></p>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Tel.Wap:
                        <br />
                        <a className="text-blue-600 dark:text-blue-500" href="http://wa.me/542216959586" target="_bank">+54 221 695-9586</a>
                        <br />
                        <a className="text-blue-600 dark:text-blue-500" href="http://wa.me/542215650270" target="_bank">+54 221 565-0270</a>
                    </p>
                    <p className="mt-3 text-lg text-gray-800 dark:text-gray-400">Urgencias penales:
                        <br />
                        <span className="text-blue-600 dark:text-blue-500">jpbernal@antoniovampa.com.ar</span>
                        <br />
                        <a className="text-blue-600 dark:text-blue-500" href="http://wa.me/542215655232" target="_bank">+54 221 565-5232</a>
                    </p>
                    <br /><br />
                    <form onSubmit={handleSubmit}>
                        <h2 className="text-2xl text-gray-800 font-ligth md:text-2xl md:leading-tight lg:text-2xl lg:leading-tight dark:text-gray-200">
                            Escríbanos:
                        </h2>
                        <br />
                        <div className="mb-4">
                            <label for="hs-hero-name-2" className="block text-sm font-medium dark:text-white" htmlFor="name"><span className="sr-only">Nombre</span></label>
                            <input className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" placeholder="Nombre"
                                id="name"
                                type="name"
                                name="name"
                            />
                            <ValidationError
                                prefix="Name"
                                field="name"
                                errors={state.errors}
                            />
                        </div>
                        <div className="mb-4">
                            <label for="hs-hero-name-2" className="block text-sm font-medium dark:text-white" htmlFor="tel"><span className="sr-only">Teléfono</span></label>
                            <input className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" placeholder="Teléfono"
                                id="tel"
                                type="tel"
                                name="tel"
                            />
                            <ValidationError
                                prefix="tel"
                                field="tel"
                                errors={state.errors}
                            />
                        </div>

                        <div className="mb-4">
                            <label for="hs-hero-email-2" className="block text-sm font-medium dark:text-white" htmlFor="email"><span className="sr-only">Email</span></label>
                            <input className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" placeholder="Email"
                                id="email"
                                type="email"
                                name="email"
                            />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                        </div>

                        <div className="mb-4">
                            <label for="hs-hero-message" className="block text-sm font-medium dark:text-white">
                                <span className="sr-only">Mensaje</span>
                            </label>
                            <textarea id="message" name="message" rows="4" className="py-3 px-4 block w-full border border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600" placeholder="Escribe tu mensaje aquí"></textarea>
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium dark:text-white">Preferencia de consulta</label>
                            <div className="flex items-center">
                                <input type="checkbox" id="presencial" name="presencial" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                                <label htmlFor="presencial" className="ml-2 block text-sm text-gray-900 dark:text-gray-400">Presencial</label>
                            </div>
                            <div className="flex items-center mt-2">
                                <input type="checkbox" id="videollamada" name="videollamada" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                                <label htmlFor="videollamada" className="ml-2 block text-sm text-gray-900 dark:text-gray-400">Videollamada</label>
                            </div>
                            <div className="flex items-center mt-2">
                                <input type="checkbox" id="llamada" name="llamada" className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded" />
                                <label htmlFor="llamada" className="ml-2 block text-sm text-gray-900 dark:text-gray-400">Llamada</label>
                            </div>
                        </div>

                        <div className="grid">
                            <button type="submit" className="py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" disabled={state.submitting}>Enviar</button>
                        </div>
                    </form>
                </div>

                <div className="lg:col-span-3 mt-10 lg:mt-0 lg:items-start">
                    <iframe title="Estudio Antonio Vampa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.532495041595!2d-57.95898842519386!3d-34.91817997417202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2e632617b5fdd%3A0xc91f2b2cc4e5c3b3!2sEstudio%20Jur%C3%ADdico%20Antonio%20-%20Vampa%20%7C%20abogados!5e0!3m2!1ses-419!2sar!4v1704478007741!5m2!1ses-419!2sar" width="100%" height="500px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    );
}

export default Contacto;
