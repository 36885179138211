import React from 'react';
import Lenguage from './Lenguage/Lenguage';
import ItemsFooter from './ItemsFooter/ItemsFooter';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto bg-zinc-300">

            <ItemsFooter />

            <div className="pt-5 mt-5 border-t border-gray-300 dark:border-gray-700">

                <div className="sm:flex sm:justify-between sm:items-center">
                    <div className="flex items-center gap-x-3">
                        <div className="space-x-4 text-sm ml-4">
                            <a className="inline-flex gap-x-2 text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200" href="#">Términos y condiciones</a>
                        </div>
                    </div>

                    <div className="flex justify-between items-center">
                        <div className="mt-3 sm:hidden">
                            <img className="w-full h-full max-w-[30px] max-h-[30px]" src="../assets/Logo.webp" alt="Logo" />
                            <p className="mt-1 text-xs sm:text-sm text-gray-600 dark:text-gray-700">© 2024 Estudio Antonio Vampa.</p>
                        </div>


                        <div className="space-x-4">
                            <a className="inline-block text-gray-500 hover:text-gray-800 dark:hover:text-gray-200" href="https://maps.app.goo.gl/6WdR4WWMtrPnyDVt6" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-map-pin"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z" /><circle cx="12" cy="10" r="3" /></svg>
                            </a>

                            <a className="inline-block text-gray-500 hover:text-gray-800 dark:hover:text-gray-200" href="https://www.linkedin.com/company/antoniovampa" target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z" /><rect width="4" height="12" x="2" y="9" /><circle cx="4" cy="4" r="2" /></svg>
                            </a>

                            <a className="inline-block text-gray-500 hover:text-gray-800 dark:hover:text-gray-200" href="https://www.instagram.com/estudio.antoniovampa/" target='_blank'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5" /><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" /><line x1="17.5" x2="17.51" y1="6.5" y2="6.5" /></svg>
                            </a>

                            <Link className="inline-block text-gray-500 hover:text-gray-800 dark:hover:text-gray-200" to="/Contacto">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-messages-square"><path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" /><path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" /></svg>
                            </Link>

                        </div>

                    </div>

                </div>
            </div>
        </footer>
    )
}

export default Footer;