import React from 'react';
import './WhatsAppButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

// Asegúrate de que el gtag.js esté cargado antes de utilizarlo
const gtag = window.gtag || function() {};

const WhatsAppButton = () => {
  const registerGAEvent = (eventName, event) => {
    if (event.target.className.includes('whatsapp-button')) {
      gtag('event', eventName, {
        'event_category': 'engagement',
        'event_label': 'WhatsApp Button_float',
        'value': 1
      });
    }
  }

  return (
    <a 
      href='https://wa.me/542216959586'
      target='_blank'
      className="whatsapp-button_float"
      onClick={(event) => registerGAEvent('Click_wapp', event)}
    >
      <FontAwesomeIcon icon={faWhatsapp} className="whatsapp-icon" />
    </a>
  );
};

export default WhatsAppButton;
