import React, { useState, useEffect } from 'react';
import TPBipData from '../../JSON/TPBip.json';

function CalculoRetroactivo() {
    const [interesesAcumulados, setInteresesAcumulados] = useState([]);
    const [retroactivoTotal, setRetroactivoTotal] = useState(0);

    useEffect(() => {
        const calcularInteresesAcumulados = () => {
            let retroactivoTotalActualizado = 0;
            let interesesAcumuladosActualizados = [];

            // Obtener la fecha actual y la fecha de hace 10 años
            const hoy = new Date();
            const diezAnios = new Date();
            diezAnios.setFullYear(hoy.getFullYear() - 10);
            diezAnios.setMonth(hoy.getMonth()); // Mantener el mismo mes que hoy

            // Iterar sobre los meses desde diez años atrás hasta el mes actual
            let fechaIteracion = new Date(diezAnios); // Clonamos la fecha inicial
            while (fechaIteracion <= hoy) {
                const mesActual = fechaIteracion.getMonth() + 1;
                const anioActual = fechaIteracion.getFullYear();
                
                // Calcular los intereses acumulados para este mes/anio
                let interesAcumulado = 0;

                let primerPeriodoEncontrado = false;
                let periodoInicial = null;
                
                TPBipData.periodos.forEach(periodo => {
                    const inicioPeriodo = new Date(periodo.desde);
                    const finPeriodo = new Date(periodo.hasta);
                    const interesPeriodo = parseFloat(periodo.total_periodo);

                    // Verificar si el período corresponde al mes/anio actual
                    if (inicioPeriodo.getMonth() === fechaIteracion.getMonth() && inicioPeriodo.getFullYear() === fechaIteracion.getFullYear()) {
                        primerPeriodoEncontrado = true;
                        periodoInicial = periodo;
                    }

                    // Si ya se encontró el primer período, sumar los intereses de los períodos siguientes
                    if (primerPeriodoEncontrado) {
                        interesAcumulado += interesPeriodo;
                    }
                });

                // Agregar los intereses acumulados para este mes/anio a la lista
                interesesAcumuladosActualizados.push({
                    mes: `${('0' + mesActual).slice(-2)}/${anioActual}`,
                    interesAcumulado: interesAcumulado,
                    totalPeriodo: interesAcumulado,
                });

                // Incrementar al siguiente mes
                fechaIteracion.setMonth(fechaIteracion.getMonth() + 1);
            }

            // Calcular el total retroactivo
            interesesAcumuladosActualizados.forEach(item => {
                retroactivoTotalActualizado += item.totalPeriodo;
            });

            // Actualizar el estado con los resultados
            setInteresesAcumulados(interesesAcumuladosActualizados);
            setRetroactivoTotal(retroactivoTotalActualizado);
        };

        // Calcular los intereses acumulados
        calcularInteresesAcumulados();
    }, []);

    return (
        <div className="text-center py-10">
            <h2 className="text-xl font-bold mb-4">Cálculo del Retroactivo</h2>
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Mes a actualizar</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Interés acumulado (%)</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total período (ARS)</th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {interesesAcumulados.map((item, index) => (
                        <tr key={index}>
                            <td className="px-6 py-4 whitespace-nowrap">{item.mes}</td>
                            <td className="px-6 py-4 whitespace-nowrap">{item.interesAcumulado}%</td>
                            <td className="px-6 py-4 whitespace-nowrap">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(item.totalPeriodo)}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td className="px-6 py-4 text-right font-bold" colSpan="2">Retroactivo actualizado</td>
                        <td className="px-6 py-4 font-bold">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(retroactivoTotal)}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
}

export default CalculoRetroactivo;
