import React, { useState, useEffect } from "react";
import CalculoRetroactivo from "./CalculoRetroactivo";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toBlob } from 'html-to-image';
import htmlToImage from 'html-to-image';
import { MetaTags } from "@wellbees/meta-tag-seo";

function Antiguedad() {
    const [trabajo1996, setTrabajo1996] = useState(false);
    const [cantidadAnios1Porciento, setCantidadAnios1Porciento] = useState("");
    const [montoSegunRecibo, setMontoSegunRecibo] = useState("");
    const [mostrarTabla, setMostrarTabla] = useState(false);
    const [basico, setBasico] = useState(0);
    const [difNueveSeis, setDifNueveSeis] = useState(0);
    const [difUno, setDifUno] = useState(0);
    const [difDos, setDifDos] = useState(0);
    const [diferenciaMensual, setDiferenciaMensual] = useState(0);
    const [mostrarCalculoRetroactivo, setMostrarCalculoRetroactivo] = useState(false); // Estado para mostrar CalculoRetroactivo
    const [retroactivoDiezAnios, setRetroactivoDiezAnios] = useState(0);
    const [sacPropDiez, setSacPropDiez] = useState(0);
    const [retroactivoCincoAnios, setRetroactivoCincoAnios] = useState(0);
    const [sacPropCinco, setSacPropCinco] = useState(0);

    const calcularDiferencia = () => {
        // Calcular basico
        const newBasico = (parseFloat(montoSegunRecibo) / parseFloat(cantidadAnios1Porciento)) * 100;
        setBasico(newBasico);

        // Calcular difNueveSeis
        const newDifNueveSeis = trabajo1996 ? newBasico * 0.03 : 0;
        setDifNueveSeis(newDifNueveSeis);

        // Calcular difUno
        const newDifUno = (newBasico * 0.02) * cantidadAnios1Porciento;
        setDifUno(newDifUno);

        // Calcular difDos
        const newDifDos = newBasico * 0.01;
        setDifDos(newDifDos);

        // Calcular diferenciaMensual
        const newDiferenciaMensual = newDifNueveSeis + newDifUno + newDifDos;
        setDiferenciaMensual(newDiferenciaMensual);

        // Calcular retroactivo y SAC para 10 años
        const newRetroactivoDiezAnios = newDiferenciaMensual * 120;
        setRetroactivoDiezAnios(newRetroactivoDiezAnios);
        const newSacPropDiez = newRetroactivoDiezAnios / 12;
        setSacPropDiez(newSacPropDiez);

        // Calcular retroactivo y SAC para 5 años
        const newRetroactivoCincoAnios = newDiferenciaMensual * 60;
        setRetroactivoCincoAnios(newRetroactivoCincoAnios);
        const newSacPropCinco = newRetroactivoCincoAnios / 12;
        setSacPropCinco(newSacPropCinco);
    };

    useEffect(() => {
        if (cantidadAnios1Porciento !== "" && montoSegunRecibo !== "") {
            calcularDiferencia();
        }
    }, [cantidadAnios1Porciento, montoSegunRecibo]);

    const toggleMostrarTabla = () => {
        // Realizar los cálculos cada vez que se presiona el botón "Generar cálculo"
        calcularDiferencia();

        // Verificar si la tabla ya está mostrada
        if (!mostrarTabla) {
            setMostrarTabla(true); // Solo cambiar el estado si la tabla no está mostrada
        }
    };



    const handlePrint = () => {
        window.print();
    };

    const copyImageToClipboard = async () => {
        const node = document.getElementById('resumen');

        try {
            const blob = await toBlob(node);
            const item = new ClipboardItem({ 'image/png': blob });
            await navigator.clipboard.write([item]);
            window.focus(); // Enfocar la ventana del navegador
            alert('Imagen copiada al portapapeles');
        } catch (error) {
            console.error('Error al copiar imagen al portapapeles:', error);
        }
    };





    return (
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8 max-w-[85rem] mx-auto">
            <MetaTags
                title='Estudio Jurídico Antonio Vampa | Calculadora Reconocimiento Antigüedad 3%'
                description='Calculadora de diferencia mensual y retroactivo ante Reclamos por Reconocimiento de Antigüedad Provincia de Buenos Aires.'
            />
            <h1 className="block text-2xl font-bold text-gray-800 sm:text-2xl md:text-2xl lg:text-2xl dark:text-white">Cálculo diferencia por Antigüedad 3%</h1>
            <div className="mb-4 mt-4">
                <label htmlFor="trabajo1996" className="inline-flex items-center">
                    <input
                        id="trabajo1996"
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={trabajo1996}
                        onChange={() => setTrabajo1996(!trabajo1996)}
                    />
                    <span className="ml-2 text-gray-700">Trabajó durante el año 1996?</span>
                </label>
            </div>
            <div className="flex justify-center mb-4" id="form">
                <div className="pl-2 pr-2">
                    <label htmlFor="cantidadAnios1Porciento" className="block text-sm font-medium text-gray-700">Cantidad de años al 1%</label>
                    <input
                        id="cantidadAnios1Porciento"
                        type="number"
                        className="py-3 px-4 block w-full border-black-900 shadow-sm rounded-lg text-sm focus:border-blue-900 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-950 dark:text-gray-400 dark:focus:ring-gray-600 bg-gray-50 text-center" placeholder="cantidad años"
                        value={cantidadAnios1Porciento}
                        onChange={(e) => setCantidadAnios1Porciento(e.target.value)}
                    />
                </div>
                <div className="pl-2 pr-2">
                    <label htmlFor="montoSegunRecibo" className="block text-sm font-medium text-gray-700">Monto según recibo:</label>
                    <input
                        id="montoSegunRecibo"
                        type="text"
                        className="py-3 px-4 block w-full border-black-900 shadow-sm rounded-lg text-sm focus:border-blue-900 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-950 dark:text-gray-400 dark:focus:ring-gray-600 bg-gray-50 text-center" placeholder="monto según recibo"
                        value={montoSegunRecibo}
                        onChange={(e) => setMontoSegunRecibo(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex justify-center py-3 px-4">
                <input
                    id="caratula"
                    type="text"
                    className="py-2 px-3 block w-2/3 md:w-1/2 lg:w-1/3 border-black-900 shadow-sm rounded-lg text-lg font-semibold focus:border-blue-900 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-950 dark:text-gray-400 dark:focus:ring-gray-600 bg-gray-50 text-center"
                    placeholder="Carátula / Observaciones"
                />
            </div>
            <div className="text-center">
                <button onClick={toggleMostrarTabla} className="py-3 px-4 shadow-sm bg-blue-500 text-white rounded-md hover:bg-blue-600">
                    Generar cálculo
                </button>
            </div>

            {mostrarTabla && (
                <div className="flex flex-col py-10 px-4 sm:px-6 lg:px-8 max-w-[50rem] mx-auto">
                    <div className="-m-1.5 overflow-x-auto">
                        <div id="resumen" className="p-1.5 min-w-full inline-block align-middle">
                            <div id="resumen" className="border rounded-lg overflow-hidden dark:border-gray-700">
                                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                    <thead>
                                        <tr className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase">
                                            <th>RESUMEN</th>
                                            <th>VALOR</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                                        <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Salario básico para el cálculo:</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(basico)}</td>
                                        </tr>
                                        {trabajo1996 && (
                                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Diferencia año 1996 (0%):</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(difNueveSeis)}</td>
                                            </tr>
                                        )}
                                        <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Diferencia años al 1%:</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(difUno)}</td>
                                        </tr>
                                        <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Diferencia año al 2%:</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(difDos)}</td>
                                        </tr>
                                        <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Total Diferencia mensual:</td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200"><strong>{new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(diferenciaMensual)}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <p className="text-gray-800 text-sm  font-medium text-end dark:text-gray-200">
                                    antoniovampa.com.ar
                                </p>
                            </div>


                            <div>
                                <div className="hs-accordion-group" data-hs-accordion-always-open>

                                    <br />
                                    <div className="hs-accordion active" id="hs-basic-always-open-heading-one">
                                        <button className="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400" aria-controls="hs-basic-always-open-collapse-one">
                                            <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                                            <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /></svg>
                                            Cálculo Retroactivo 10 años:
                                        </button>
                                        <div id="hs-basic-always-open-collapse-one" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-always-open-heading-one">
                                            <p className="text-gray-800 text-sm text-start dark:text-gray-200">
                                                <em>Cálculo prescripción a 10 años (sin intereses).</em>
                                            </p>
                                            <div class="flex flex-col">
                                                <div class="-m-1.5 overflow-x-auto">
                                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                                        <div class="overflow-hidden">
                                                            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Retroactivo</th>
                                                                        <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Sac Proporcional</th>
                                                                        <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">TOTAL</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                                                                    <tr>
                                                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200 text-center">
                                                                            {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(retroactivoDiezAnios)}
                                                                        </td>
                                                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 text-center">
                                                                            {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(sacPropDiez)}
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 text-center">
                                                                            <strong>
                                                                                {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(retroactivoDiezAnios + sacPropDiez)}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <br />
                                    <div className="hs-accordion active" id="hs-basic-always-open-heading-one">
                                        <button className="hs-accordion-toggle hs-accordion-active:text-blue-600 py-3 inline-flex items-center gap-x-3 w-full font-semibold text-start text-gray-800 hover:text-gray-500 rounded-lg disabled:opacity-50 disabled:pointer-events-none dark:hs-accordion-active:text-blue-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:text-gray-400" aria-controls="hs-basic-always-open-collapse-one">
                                            <svg className="hs-accordion-active:hidden block size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                                            <svg className="hs-accordion-active:block hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h14" /></svg>
                                            Cálculo Retroactivo 5 años:
                                        </button>
                                        <div id="hs-basic-always-open-collapse-one" className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300" aria-labelledby="hs-basic-always-open-heading-one">
                                            <p className="text-gray-800 text-sm text-start dark:text-gray-200">
                                                <em>*Cálculo prescripción a 5 años (sin intereses).</em>
                                            </p>
                                            <div class="flex flex-col">
                                                <div class="-m-1.5 overflow-x-auto">
                                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                                        <div class="overflow-hidden">
                                                            <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Retroactivo</th>
                                                                        <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Sac Proporcional</th>
                                                                        <th scope="col" class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">TOTAL</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                                                                    <tr>
                                                                        <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200 text-center">
                                                                            {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(retroactivoCincoAnios)}
                                                                        </td>
                                                                        <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 text-center">
                                                                            {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(sacPropCinco)}
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200 text-center">
                                                                            <strong>
                                                                                {new Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(retroactivoCincoAnios + sacPropCinco)}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="py-3 px-4 mt-4 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handlePrint}>
                        Imprimir pantalla
                    </button>
                    <div className="flex justify-end">
                        <CopyToClipboard text="Copiar como imagen" onCopy={copyImageToClipboard}>
                            <button className="py-3 px-4 max-w-[10rem] text-center  text-xs mt-4 bg-gray-300 text-white rounded-md hover:bg-gray-600">
                                Copiar como Imagen
                            </button>
                        </CopyToClipboard>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Antiguedad;
