import React, { useState, useEffect } from "react";
import datos from '../../JSON/resultados_Minify.json';
import { MetaTags } from "@wellbees/meta-tag-seo";

function LiquidacionTotal() {
  const fecha_actualizacion = '08/02/2024';
  const [totalDeuda, setTotalDeuda] = useState("$0.00");
  const [sacProporcional, setSacProporcional] = useState("$0.00");
  const [totalLiquidacion, setTotalLiquidacion] = useState("$0.00");
  const [sociosProcesados, setSociosProcesados] = useState(0); // Nuevo estado para el total de socios procesados
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    calcularTotalDeuda();
    calcularSociosProcesados(); // Calcular el total de socios procesados al cargar la página
  }, []);

  const calcularTotalDeuda = () => {
    let total = 0;
    for (const persona in datos) {
      if (datos.hasOwnProperty(persona)) {
        const deuda = datos[persona].deuda;
        for (const fecha in deuda) {
          if (deuda.hasOwnProperty(fecha)) {
            total += deuda[fecha].total_mes;
          }
        }
      }
    }

    const formattedTotal = total.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS'
    });

    setTotalDeuda(formattedTotal);

    // Calcular el SAC proporcional sobre total
    const sacProp = total/12;
    const formattedSacProp = sacProp.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS'
    });
    setSacProporcional(formattedSacProp);

    // Calcular el total de la liquidación
    const totalLiquidacionAmount = total + sacProp;
    const formattedTotalLiquidacion = totalLiquidacionAmount.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS'
    });
    setTotalLiquidacion(formattedTotalLiquidacion);

    setIsLoading(false);
  };

  const calcularSociosProcesados = () => {
    // Calcular el total de socios procesados
    let totalSocios = Object.keys(datos).length;
    setSociosProcesados(totalSocios);
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="flex flex-col py-10 px-4 sm:px-6 lg:px-8 max-w-[50rem] mx-auto">
       <MetaTags 
            title='Antonio-Vampa | CSRSPBA Liquidacion Total'
            description='Liquidación judicial Centro de Suboficiales Servicio Penitenciario Bonaerense.'
            />
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden dark:border-gray-700">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Fecha de actualización:</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{fecha_actualizacion}</td>
                </tr>
                <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Socios procesados:</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{sociosProcesados}</td>
                </tr>
                <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Deuda Total:</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{totalDeuda}</td>
                </tr>
                <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">SAC Proporcional:</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 dark:text-gray-200">{sacProporcional}</td>
                </tr>
                <tr className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>Total Liquidación:</strong></td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200"><strong>{totalLiquidacion}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="flex justify-center items-center">
          <button type="button" className="flex justify-center items-center h-[2.875rem] w-[2.875rem] text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
              <span className="sr-only">Loading...</span>
            </span>
          </button>
          <button type="button" className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            <span className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading"></span>
            Loading
          </button>
        </div>
      )}
      <button className="py-3 px-4 mt-4 bg-blue-500 text-white rounded-md hover:bg-blue-600" onClick={handlePrint}>
        Imprimir pantalla
      </button>
    </div>
  )
}

export default LiquidacionTotal;
