import React from "react";
import { Link } from "react-router-dom";
import "./Servicios.css"
import { MetaTags } from "@wellbees/meta-tag-seo";
import FAQ_Sucesiones from "../../FAQs/FAQ_Sucesiones";


function Sucesiones() {

    const registerGAEvent = (eventName) => {
        if (typeof window.gtag === 'function') {
            window.gtag('event', eventName, {
                'event_category': 'engagement',
                'event_label': 'WhatsApp Button_Suc',
                'value': 1
            });
        }
    };

    const handleWhatsAppClick = (event) => {
        if (event.type === 'Click_wapp') {
            registerGAEvent('Click_wapp');
        }
    };

    const suc = [
        {
            title: "Sucesiones",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M11.25 4.533A9.707 9.707 0 0 0 6 3a9.735 9.735 0 0 0-3.25.555.75.75 0 0 0-.5.707v14.25a.75.75 0 0 0 1 .707A8.237 8.237 0 0 1 6 18.75c1.995 0 3.823.707 5.25 1.886V4.533ZM12.75 20.636A8.214 8.214 0 0 1 18 18.75c.966 0 1.89.166 2.75.47a.75.75 0 0 0 1-.708V4.262a.75.75 0 0 0-.5-.707A9.735 9.735 0 0 0 18 3a9.707 9.707 0 0 0-5.25 1.533v16.103Z" />
            </svg>,
            items: [
                'Sucesiones',
                'Testamentos',
                'Declaratoria de Herederos',
                'Mediación Familiar',
                'División y Adjudicación de Bienes',
                'Inventario y Valuación de Bienes',
                'Pago de Impuestos Sucesorios',
                'Liquidación de Deudas del Causante',
                'Asesoramiento Legal en Herencias',
                'Tramitación de Testamentos y Legados',
                'Resolución de Conflictos Hereditarios',
                'Representación Judicial en Sucesiones',
                'Gestión de Propiedades Heredadas',
                'Consultoría en Sucesiones Internacionales'
            ]
        },
        {
            title: "Planificación Hereditaria",
            iconSVG: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path d="M11.644 1.59a.75.75 0 0 1 .712 0l9.75 5.25a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.712 0l-9.75-5.25a.75.75 0 0 1 0-1.32l9.75-5.25Z" />
                <path d="m3.265 10.602 7.668 4.129a2.25 2.25 0 0 0 2.134 0l7.668-4.13 1.37.739a.75.75 0 0 1 0 1.32l-9.75 5.25a.75.75 0 0 1-.71 0l-9.75-5.25a.75.75 0 0 1 0-1.32l1.37-.738Z" />
                <path d="m10.933 19.231-7.668-4.13-1.37.739a.75.75 0 0 0 0 1.32l9.75 5.25c.221.12.489.12.71 0l9.75-5.25a.75.75 0 0 0 0-1.32l-1.37-.738-7.668 4.13a2.25 2.25 0 0 1-2.134-.001Z" />
            </svg>,
            items: [
                'Elaboración de Testamentos',
                'Planificación Patrimonial',
                'Creación de Fideicomisos',
                'Asesoramiento en Donaciones',
                'Protección de Bienes',
                'Planificación Fiscal Sucesoria',
                'Asesoramiento en Seguros de Vida',
                'Estructuración de Empresas Familiares',
                'Planificación para Personas con Necesidades Especiales',
                'Asesoramiento en Designación de Beneficiarios'
            ]
        },
    ]

    const svgItem = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
        <path fillRule="evenodd" d="M6.22 4.22a.75.75 0 0 1 1.06 0l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06L8.94 8 6.22 5.28a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" /></svg>

    return (
        <div>
            <div className="max-w-[85rem] px-4 py-10 sm:px-7 lg:px-16 lg:py-10 mx-auto">
                <MetaTags
                    title="Sucesiones | Estudio Jurídico Antonio Vampa | Abogados La Plata"
                    description="En EAV, especialistas en soluciones integrales y personalizadas en sucesiones y planificación hereditaria. Orientación experta desde la tramitación de declaratorias de herederos hasta la gestión de propiedades y resolución de conflictos familiares. Protegemos su legado con asesoramiento en planificación patrimonial y fiscal. Rápidos, confiables y eficaces."
                    keywords="sucesiones, herencia, planificación hereditaria, orientación experta, declaratorias de herederos, gestión de propiedades, resolución de conflictos familiares, protección del legado familiar, planificación patrimonial, asesoramiento fiscal, rápida respuesta legal, confiables abogados, eficacia legal"
                />
                <div className="grid md:grid-cols-2 gap-5">
                    <div className="lg:w-3/10">
                        <h2 className="text-3xl text-gray-800 font-bold lg:text-4xl dark:text-white">
                            Sucesiones y Planificación Hereditaria
                        </h2>
                        <p className="mt-3 text-gray-800 dark:text-gray-400">
                            En EAV, nos especializamos en proporcionar soluciones integrales y personalizadas en sucesiones y planificación hereditaria. Nuestro enfoque se centra en ofrecer orientación experta y estratégica a través de cada fase del proceso sucesorio, desde la tramitación eficiente de declaratorias de herederos hasta la gestión experta de propiedades heredadas y la resolución de conflictos familiares mediante mediación y procurando obtener los mejores soluciones posibles. Nos dedicamos a proteger y preservar su legado familiar asesorando en planificación patrimonial y fiscal, asegurando la seguridad y el cumplimiento de sus deseos.
                            <br /><br />
                            Somos rápidos, confiables y eficaces.
                        </p>

                        <Link to="/Contacto">
                            <p className="mt-5">
                                <p className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500" >
                                    Contáctenos
                                    <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                                </p>
                            </p>
                        </Link>
                        <p className="mt-3">
                            <a href="https://wa.me/542216959586" target="_blank" rel="noopener noreferrer"
                                className="whatsapp-button_Suc"
                                onClick={handleWhatsAppClick}>
                                <span className="inline-flex items-center gap-x-1 font-medium text-blue-600 dark:text-blue-500">
                                    Envíenos un WhatsApp
                                    <svg className="flex-shrink-0 w-4 h-4 transition ease-in-out group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                                </span>
                            </a>
                        </p>
                    </div>

                    <div id="materias_box" className="space-y-6 lg:space-y-10">
                        {suc.map(points => (
                            <div id="item_box">
                                <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
                                    {points.iconSVG}
                                </span>
                                <div className="ms-5 sm:ms-0.01">
                                    <h3 className="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                        {points.title}
                                    </h3>
                                    <ul>
                                        {points.items.map(item => (
                                            <li className="flex">
                                                {svgItem}
                                                <p>{item}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <div className="max-w-[85rem] px-4 py-10 sm:px-7 lg:px-16 lg:py-10 mx-auto">
            <FAQ_Sucesiones />
            </div>
        </div>


    )
}

export default Sucesiones;